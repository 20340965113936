import getStringWithSpecialSymbols from '../../../helpers/getStringWithSpecialSymbols';
import { WlProviderBuilderProps } from './WlProviderBuilderContainer';
import getCustomSelectItems from "../../../creators/getCustomSelectItems";

export function getFieldsForState(
  this: { props: WlProviderBuilderProps; state },
  data
) {
  const {
    id: dataId,
    title,
    wl_id,
    provider_id,
    status,
    created_at,
    created_by,
    updated_at,
    updated_by,
  } = data;
  
  const {
    allWlIds,
    wlProviderStatus,
    allPaymentSystems
  } = this.props;

  const result = {
    id: dataId,
    title: getStringWithSpecialSymbols(title),
    created_at,
    created_by,
    updated_at,
    updated_by,
  };

  const wlIdList = getCustomSelectItems({
    list: allWlIds,
    getLabel: ({ text }) => text,
    getValue: (item) => item.id,
  });
  const wlIdSelected = wlIdList.find((wl) => wl.value === wl_id) || null;
  const paymentSystemsList = getCustomSelectItems({
    list: allPaymentSystems,
    getLabel: ({ text }) => text,
    getValue: (item) => item.id,
  });
  const providerIdSelected = paymentSystemsList.find((provider) => provider.value === provider_id) || null;
  const statuses = getCustomSelectItems({
    list: wlProviderStatus,
    getLabel: (item) => item.text,
    getValue: (item) => item.id,
  });
  const selectedStatus = statuses.find((oneOfTheStatuses) => oneOfTheStatuses.value === status) || null;

  return {
    ...result,
    wl_id: {
      selected: wlIdSelected?.value,
      value: wlIdSelected,
      list: wlIdList,
    },
    provider_id: {
      selected: providerIdSelected?.value,
      value: providerIdSelected,
      list: paymentSystemsList,
    },
    status: {
      selected: selectedStatus?.value,
      value: selectedStatus,
      list: statuses,
    },
  };
}
