import BaseMessageHandler from '../BaseMessageHandler';
import { updateDataItem } from 'actions/getData';
import tableNames from 'constants/tableNames';

export default class Provider_Enable extends BaseMessageHandler {
  success = () => {
    const { dispatch, message } = this;
    dispatch(updateDataItem(tableNames.providers, 'id', message.payload));
  };
}
