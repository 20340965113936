import React from 'react';
import { withRouter } from 'react-router-dom';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { WithRouterProps } from 'decorators/withRouter';
import Button from 'components/ui/button';
import path from 'helpers/path';
import Messages from 'constants/rpcTypes';

interface OwnProps {
  customClass?: string;
}

type Props = OwnProps & IntlProps & WithPermissions & WithRouterProps;

const CreateProviderButton: React.FC<Props> = ({
  history,
  getTranslate,
  isEnabled,
  customClass = '',
}) => {
  const canCreate = isEnabled(Messages.Provider_Create);
  return (
    <Button
      status='primary'
      onClick={() =>
        history.push(path('/providers/create'))
      }
      text={getTranslate('providers.wlProvider.newprovider.button')}
      disabled={!canCreate}
      customClass={customClass}
      tooltip={
        !canCreate
          ? getTranslate('myteam.viewOnly.hint')
          : undefined
      }
    />
  );
};

export default withRouter(addTranslation(addPermissions(CreateProviderButton)));
