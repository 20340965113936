import urlsMap from 'constants/urlsMap';
import RpcTypes from 'constants/rpcTypes';

type MenuItem = {
  key: string;
  customKey?: string;
  icon: string;
  customUrl?: string;
  permissions?: RpcTypes[];
  metricKey?: string;
  adminTool?: boolean;
};

const menuConfiguration: MenuItem[] = [
  {
    key: 'operations',
    icon: 'im-Payments',
    permissions: [RpcTypes.Operations_List],
  },
  {
    key: 'reports',
    icon: 'im-Reports',
    permissions: [RpcTypes.ReportTemplateTaskHistory_List],
  },
  {
    key: 'providers',
    icon: 'providers',
    permissions: [RpcTypes.Provider_List],
  },
  {
    key: 'adminTools',
    customUrl: 'admin-tools',
    icon: 'im-Configurator-2',
    permissions: [
      RpcTypes.Localize_LexemesList,
      RpcTypes.ApsIcon_List,
      RpcTypes.User_List,
    ],
  },
  {
    key: 'userManage',
    customUrl: 'admin',
    icon: 'im-Avatar',
    permissions: [RpcTypes.User_List],
    adminTool: true,
  },
  {
    key: 'iconManage',
    icon: 'im-Calendar',
    permissions: [RpcTypes.ApsIcon_List],
    adminTool: true,
    customUrl: 'icons',
  },
  {
    key: 'team',
    icon: 'im-My-team',
    permissions: [RpcTypes.Team_ListUsers],
    metricKey: 'myTeam',
  },

  /*{
    key: 'getStarted',
    icon: 'im-Home',
    customUrl: 'get-started',
    permissions: [RpcTypes.DemoStage_Project, RpcTypes.GetStarted_View],
  },
  {
    key: 'analytics',
    icon: 'im-Analytics',
    permissions: [RpcTypes.Analytics_Ts],
  },
  {
    key: 'payments',
    icon: 'im-Payments',
    permissions: [RpcTypes.Payments_List],
  },
  {
    key: 'payouts',
    customUrl: urlsMap.payouts,
    icon: 'im-Payout',
    permissions: [
      RpcTypes.PaymentOperation_Payout,
      RpcTypes.BulkPayouts_List,
      RpcTypes.BulkPayouts_UploadPayout,
      RpcTypes.BulkPayouts_UploadRefund,
      RpcTypes.MotoPayment_Create,
    ],
    metricKey: 'manualPayments',
  },
  {
    key: 'remittance',
    icon: 'im-Remmitance',
    permissions: [RpcTypes.Remittance_ListRecipientAccounts],
  },
  {
    key: 'invoices',
    icon: 'im-Invoice',
    permissions: [RpcTypes.Invoice_List],
  },
  {
    key: 'subscription',
    customUrl: 'recurring-payments',
    icon: 'im-Subscription',
    permissions: [RpcTypes.SubscriptionPayment_List],
    metricKey: 'subscriptions',
  },
  {
    key: 'references',
    icon: 'im-References',
    permissions: [RpcTypes.PaymentOperationReference_List],
  },
  
  {
    key: 'finance',
    icon: 'im-Finance',
    permissions: [
      RpcTypes.Balance_Get,
      RpcTypes.Balance_RealTime,
      RpcTypes.Balance_Statement,
      RpcTypes.FinancialReport_ListRequest,
    ],
  },
  {
    key: 'risks',
    icon: 'im-Risk',
    permissions: [RpcTypes.Rcs_ListBlackWhite, RpcTypes.Rcs_ListFraud],
  },
  {
    key: 'chargebacks',
    icon: 'im-Chargebacks',
    permissions: [RpcTypes.Chargeback_List],
  },
  {
    key: 'projects',
    icon: 'im-Projects',
    permissions: [RpcTypes.Project_Get],
  },
  {
    key: 'maf',
    icon: 'im-Many-Files',
    permissions: [RpcTypes.Onboarding_Business_Docs],
    metricKey: 'businessDocs',
  },
  {
    key: 'reconciliation',
    customKey: 'Reconciliation',
    icon: 'im-Reconcilations',
    permissions: [RpcTypes.Reconciliation_Get, RpcTypes.Reconciliation_SetData],
  },
  {
    key: 'adminTools',
    customUrl: 'admin-tools',
    icon: 'im-Configurator-2',
    permissions: [
      RpcTypes.Localize_LexemesList,
      RpcTypes.ApsIcon_List,
      RpcTypes.User_List,
    ],
  },
  {
    key: 'localizationManage',
    customUrl: 'lcmanage',
    icon: 'im-Multichoise',
    permissions: [RpcTypes.Localize_LexemesList],
    adminTool: true,
  },
  {
    key: 'iconManage',
    icon: 'im-Calendar',
    permissions: [RpcTypes.ApsIcon_List],
    adminTool: true,
    customUrl: 'icons',
  },
  {
    key: 'userManage',
    customUrl: 'admin',
    icon: 'im-Avatar',
    permissions: [RpcTypes.User_List],
    adminTool: true,
  },
  {
    key: 'financialData',
    customUrl: 'admin-fin-data',
    icon: 'im-Finance',
    permissions: [RpcTypes.FinancialData_FileList],
    adminTool: true,
  },
 */
];

export default menuConfiguration;
