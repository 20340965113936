import BaseMessageHandler from '../BaseMessageHandler';
import { addDataItem } from 'actions/getData';
import Repository from 'helpers/Repository';
import tableNames from 'constants/tableNames';

export default class Provider_Create extends BaseMessageHandler {
  success = () => {
    const { dispatch, message } = this;
    const { payload } = message;

    const store = Repository.get('store');
    const {
      data: { providers },
    } = store.getState();

    if (providers.items.length) {
      dispatch(addDataItem(tableNames.providers, payload));
    }
  };
}
