import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getProvider, createProvider, updateProvider } from 'api/providers';
import { WithRouterProps } from '../../../decorators/withRouter';
import { withRouter } from 'react-router-dom';
import { addTranslation, IntlProps } from '../../../decorators/addTranslation';
import {
  addPermissions,
  WithPermissions,
} from '../../../decorators/addPermissions';
import { AnyObject } from '../../../types/Common';
import { throttle } from 'lodash';
import { getFieldsForState } from './helpers';
import { WlProviderFields } from './WlProviderFieldsInterface';
import breakpoints from '../../../constants/breakpoints';
import checkFilters from '../../../helpers/checkFilters';
import Messages from '../../../constants/rpcTypes';
import { StoreProps } from '../../../store';
import Loader from '../../ui/loader';
import WlProviderFormSection from './WlProviderFormSection';
import { fieldsConfig } from './FieldsConfig';
import { scrollToError } from '../../formFields/helpers';
import WlProviderBuilder from './WlProviderBuilder';
import { addListeners } from '../../../decorators/addListeners';
import MetricService from '../../../helpers/metricService/MetricService';

interface ConnectedProps {
  wlProvider: {
    wl_id: string;
    provider_id: string;
    title: string;
    status: string;
    created_at: string;
    created_by: string;
    updated_at: string;
    updated_by: string;
  };
  providerId: string | null;
  isEditing: boolean;
  allWlIds: AnyObject[];
  wlProviderStatus: AnyObject[];
  allPaymentSystems: AnyObject[];
  isLoadingDictionaries: boolean;
}
type RouterProps = WithRouterProps<{ id?: string }>;
type Props = ConnectedProps &
  StoreProps &
  IntlProps &
  WithPermissions &
  RouterProps;

export type WlProviderBuilderProps = Props;

const initialData = {
  id: '',
  wl_id: '',
  provider_id: '',
  title: '',
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  status: '',
};

interface State {
  isFetch: boolean;
  isLoading: boolean;
  canEdit: boolean;
  fields: WlProviderFields;
  backendError: boolean;
  isSubmitted: boolean;
  isMobile: boolean;
  validationErrors: AnyObject;
}

@addListeners([Messages.Provider_Update, Messages.Provider_Create])
class WlProviderBuilderContainer extends Component<Props, State> {
  private readonly getFieldsForState: (data, isDisabled?) => WlProviderFields;
  private readonly updateProvider: (userId) => void;
  private readonly getProvider: (userId) => AnyObject;
  private readonly createProvider: (data) => void;

  constructor(props) {
    super(props);

    this.getFieldsForState = getFieldsForState.bind(this);
    this.updateProvider = updateProvider;
    this.getProvider = getProvider;
    this.createProvider = createProvider;
    this.handleResize = throttle(this.handleResize, 300);

    this.state = {
      isFetch: false,
      isLoading: false,
      canEdit: true,
      fields: this.getFieldsForState(initialData),
      isSubmitted: false,
      validationErrors: {},
      backendError: false,
      isMobile: window.innerWidth <= breakpoints.providerBuilder,
    };
  }

  async componentDidMount() {
    let wlProvider;
    if (this.props.providerId) {
      wlProvider = await this.getProvider(this.props.providerId);
    }

    await checkFilters('providers');

    await this.init(wlProvider);
    window.addEventListener('resize', this.handleResize);
  }

  init = async (wlProvider: AnyObject | null = null) => {
    const { isEditing, isEnabled } = this.props;

    const canEdit =
      isEnabled(Messages.Provider_Update) && (!isEditing || !!wlProvider);

    this.setState({
      fields: this.getFieldsForState(wlProvider || initialData, !canEdit),
      isFetch: true,
      canEdit,
    });
  };

  render() {
    const { isEditing } = this.props;
    const { fields, isFetch, canEdit, isLoading, isSubmitted, backendError } =
      this.state;

    if (!isFetch || !fields) {
      return <Loader />;
    }

    return (
      <WlProviderBuilder
        canEdit={Boolean(canEdit)}
        isEditing={isEditing}
        isLoading={isLoading}
        isSubmitted={isSubmitted}
        canSendForm={this.canSendForm()}
        onSend={() => this.send()}
        backendError={backendError}
        fields={fields as WlProviderFields}
        renderForm={this.renderForm}
        backToForm={this.backToForm}
      />
    );
  }

  handleResize = () => {
    if (window.innerWidth > breakpoints.userBuilder) {
      this.setState({ isMobile: false });
    } else {
      this.setState({ isMobile: true });
    }
  };

  backToForm = async () => {
    const { isEditing } = this.props;
    
    if (!isEditing) {
      this.setState(() => ({
        fields: this.getFieldsForState(initialData),
        isSubmitted: false,
      }));
      return;
    }
    
    this.setState(() => ({
      isSubmitted: false,
    }));
  };

  renderForm = () => {
    const { fields, validationErrors } = this.state;

    const { getTranslate, isEditing } = this.props;

    return (
      <WlProviderFormSection
        updateFiltersValues={this.updateFiltersValues}
        fieldsConfig={fieldsConfig(isEditing)}
        fields={fields}
        validationErrors={validationErrors}
        onChangeField={this.changeField}
        getTranslate={getTranslate}
      />
    );
  };

  updateFiltersValues = (key, value) => {
    this.setState({ [key]: value } as Pick<State, keyof State>);
  };

  changeField = (key: string, value: any) => {
    const { fields } = this.state;

    const updatedFields = {
      ...fields,
      [key]: value,
    };

    this.setState({
      fields: updatedFields,
      validationErrors: {},
    });
  };

  async send() {
    const { providerId, isEditing } = this.props;
    const { backendError, canEdit } = this.state;

    if (!canEdit) return;

    let action;
    if (isEditing) {
      action = this.updateProvider;
    } else {
      action = this.createProvider;
    }

    this.setState({ isLoading: true, isSubmitted: false });

    try {
      const data: any = this.getFieldsForBackend();
      if (isEditing) {
        data.providerId = providerId;
      }

      await action(data);

      if (backendError) {
        this.setState({ backendError: false });
      }
    } catch (error) {
      const { payload, status } = error;
      if (status === 'error') {
        this.setState({ backendError: true });
      }
      if (payload && payload.validationErrors) {
        this.setState({
          validationErrors: payload.validationErrors,
        });
        scrollToError(payload.validationErrors);
      }
    } finally {
      this.setState({ isLoading: false });
    }
  }

  getFieldsForBackend() {
    const { fields } = this.state;
    const { id, wl_id, provider_id, title, status } = fields;

    const commonFields = {
      wl_provider_id: id,
      wl_id: wl_id?.selected,
      provider_id: provider_id?.selected,
      title: title,
      status: status?.selected,
    };

    return {
      ...commonFields,
    };
  }

  canSendForm(): boolean {
    const fields = this.getFieldsForBackend();
    const { wl_id, provider_id, title, status } = fields;

    return Boolean(wl_id && provider_id && title && status);
  }

  onEvent({ data }) {
    const { isEditing } = this.props;
    if (!data.payload.validationErrors) {
      this.setState({
        isSubmitted: true,
      });
    }

    if (data.payload.id) {
      this.setState({
        fields: this.getFieldsForState(data.payload),
      });
    }

    MetricService.send({
      action: 'click',
      actionKey: isEditing
        ? 'providers.editProvider.save.yes'
        : 'providers.newProvider.create',
    });
  }
}

const mapStateToProps = (state, ownProps: RouterProps): ConnectedProps => {
  const providerId = ownProps.match?.params?.id || null;
  const { filtersValues } = state;
  return {
    wlProvider: state.wlProvider,
    providerId,
    isEditing: Boolean(providerId),
    allWlIds: filtersValues?.allWlIds?.list,
    allPaymentSystems: filtersValues?.allPaymentSystems?.list,
    wlProviderStatus: filtersValues?.wlProviderStatus?.list,
    isLoadingDictionaries: !filtersValues,
  };
};

export default withRouter(
  connect(mapStateToProps)(
    addTranslation(addPermissions(WlProviderBuilderContainer))
  )
);
