import types from 'components/ui/dataBlock/types';

/**
 * Класс преобразования данных из DWH в наш формат для компонента DataBlock
 */
class OperationDataMapper {
  static getOperationInfo(operationData) {
    const { payment_provider_info, callback, paymentMethodType, iconUrl } =
      operationData;

    return {
      operationInfo: [
        {
          key: 'payments.data.operationType',
          value: operationData.operationType,
        },
        {
          key: 'payments.data.operationStatus',
          value: operationData.operationStatus,
          type: types.status,
        },
        {
          key: 'payments.data.amount',
          value: `${operationData.amount}\u00A0${operationData.currency}`,
        },
        {
          key: 'payments.data.created',
          value: operationData.operationCreatedAt,
        },
        {
          key: 'payments.data.updated',
          value: operationData.operationUpdatedAt,
        },
        {
          key: 'payments.data.merchant',
          value: operationData.merchant,
        },
        {
          key: 'payments.data.mid',
          value: operationData.mid,
        },
      ],

      providerInfo: [
        {
          key: 'payments.data.providerPaymentID',
          value: operationData.providerPaymentId,
        },
        {
          key: 'payments.data.authCode',
          value: operationData.authorisationCode,
        },
        {
          key: 'payments.data.paymentProviderDate',
          value: operationData.paymentProviderDate,
        },
        {
          key: 'payments.data.reasonCode',
          value: operationData.reasonCode,
        },
        {
          key: 'payments.data.reasonCodeDescription',
          value: operationData.reasonMessage,
        },
        {
          key: 'payments.data.ARN',
          value: operationData.arn,
        },
        {
          key: 'payments.data.RRN',
          value: operationData.rrn,
        },
      ],

      paymentMethodInfo: [
        {
          key: 'payments.data.paymentMethod',
          value: operationData.paymentMethod,
        },
        {
          key: 'payments.data.paymentMethodType',
          value:
            !iconUrl && !paymentMethodType
              ? undefined
              : { iconUrl, paymentMethodType },
          type: types.image,
        },
        {
          key: 'payments.data.accountNumber',
          value: operationData.accountNumber,
        },
        {
          key: 'payments.data.cardHolder',
          value: operationData.cardHolder,
        },
        {
          key: 'payments.data.cardExpiry',
          value: operationData.cardExpiryDate,
        },
        {
          key: 'payments.data.bankName',
          value: operationData.bankName,
        },
        {
          key: 'payments.data.countryByCardBIN',
          value: operationData.countryByBin,
        },
        { key: 'payments.data.BIN', value: operationData.bin },
      ],

      customerInfo: [
        { key: 'payments.data.customer_id', value: operationData.customerId },
        {
          key: 'payments.data.customer_name',
          value: operationData.customerName,
        },
        { 
          key: 'payments.data.dateofbirth',
          value: operationData.dateOfBirth
        },
        {
          key: 'payments.data.email',
          value: operationData.email
        },
        {
          key: 'payments.data.phone',
          value: operationData.phone
        },
        {
          key: 'payments.data.city',
          value: operationData.city
        },
        {
          key: 'payments.data.country',
          value: operationData.country
        },
        {
          key: 'payments.data.customerIp',
          value: operationData.customerIp
        },
        {
          key: 'payments.data.countryByIP',
          value: operationData.countryByIp
        },
        {
          key: 'payments.data.device',
          value: operationData.device
        },
        {
          key: 'payments.data.browser',
          value: operationData.browser
        },
        {
          key: 'payments.data.screenResolution',
          value: operationData.screenResolution
        },
      ],

      callback: [],

      endpoint: [
      ],

      secure: [
      ],

      address: {
      },

      fraud: {
      },

      cryptoInfo: {
      },
    };
  }

  static getPaymentInfo(paymentData) {
    const {
      payment,
      customer_info,
      payment_method,
      billing_info,
      lastCallback: callback,
      remittanceRecipientAccount,
    } = paymentData;

    const { iconUrl, paymentMethodType } = payment_method;

    return {
      paymentInfo: [
        { key: 'payments.data.paymentID', value: payment.paymentId },
        { key: 'payments.data.token', value: payment.card_token },
        { key: 'payments.data.paymentType', value: payment.paymentType },
        {
          key: 'payments.data.paymentMethod',
          value: payment.paymentMethodGroup,
        },
        {
          key: 'payments.data.paymentMethodType',
          value:
            !iconUrl && !paymentMethodType
              ? undefined
              : { iconUrl, paymentMethodType },
          type: types.image,
        },
        {
          key: 'payments.data.accountNumber',
          value: payment_method.paymentAccount,
        },
        {
          key: 'payments.data.cardProductType',
          value: payment_method.customer_card_product_type,
        },
        {
          key: 'payments.data.amount',
          value: `${payment.paymentAmount}\u00A0${payment.paymentCurrency}`,
        },
        {
          key: 'payments.data.channelAmount',
          value: `${payment.channelAmount}\u00A0${payment.channelCurrency}`,
        },
        // { key: 'payments.data.paymentFee', value: null }, //DE-350 - убрали пока нет обсчета
        {
          key: 'payments.data.paymentStatus',
          value: payment.paymentStatus,
          type: types.status,
        },
        { key: 'payments.data.created', value: payment.creationDate },
        { key: 'payments.data.updated', value: payment.updatedDate },
        {
          key: 'payments.data.description',
          value: payment.payment_description,
        },
        { key: 'payments.data.COF', value: payment.recurring },
      ],

      projectInfo: [
        { key: 'payments.data.projectID', value: payment.projectId },
        { key: 'payments.data.projectName', value: payment.projectName },
        { key: 'payments.data.projectURL', value: payment.projectUrl },
        { key: 'payments.data.brand', value: payment.company_name },
      ],

      customerInfo: [
        { key: 'payments.data.customer_id', value: customer_info.customerId },
        {
          key: 'payments.data.customer_name',
          value:
            customer_info.customerFirstName +
            ' ' +
            customer_info.customerLastName,
        },
        { key: 'payments.data.dateofbirth', value: customer_info.customerDOB },
      ],

      customerIp: [
        { key: 'payments.data.customerIP', value: customer_info.customerIp },
        { key: 'payments.data.countryByIP', value: customer_info.countryByIp },
      ],

      contactInfo: [
        { key: 'payments.data.email', value: customer_info.customerEmail },
        { key: 'payments.data.phone', value: customer_info.customerPhone },
        { key: 'payments.data.city', value: customer_info.customerCity },
        { key: 'payments.data.country', value: customer_info.customerCountry },
      ],

      billingInfo: [
        {
          key: 'payments.data.billingAddress',
          value: billing_info.billingAddress,
        },
        { key: 'payments.data.billingCity', value: billing_info.billingCity },
        {
          key: 'payments.data.billingRegion',
          value: billing_info.billingRegion,
        },
        {
          key: 'payments.data.BillingCountry',
          value: billing_info.billingCountry,
        },
        {
          key: 'payments.data.BillingPostalCode',
          value: billing_info.billingPostal,
        },
      ],

      deviceInfo: [
        { key: 'payments.data.device', value: null },
        { key: 'payments.data.browser', value: null },
        { key: 'payments.data.screenResolution', value: null },
        { key: 'payments.data.sessionID', value: null },
      ],
      callback: [],
      remittanceRecipientAccount: [],
    };
  }
}

export default OperationDataMapper;
