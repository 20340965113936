import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Animation from 'components/ui/animation';
import Button from 'components/ui/button';
import Input from 'components/ui/input';
import FiltersButton from 'components/filters/FiltersButton';
import MetricService from 'helpers/metricService/MetricService';
import filtersKeys from 'constants/filters';
import { QuickMyTeamFiltersType } from 'types/QuickFilters';
import { filtersKeysType } from 'types/Filters';
import { AnyObject } from 'types/Common';
import './quickFilters.scss';

interface Props extends IntlProps {
  filters: QuickMyTeamFiltersType;
  onChangeFilter: (key: filtersKeysType, value: any) => void;
  onChangeCurrentTable: (tableName) => void;
  tableName: string;
  filtersValues: AnyObject;
  onResetFilters: () => void;
  hasSelectedQuickFilters: boolean;
  hasSelectedFilters: boolean;
  isAdmin: boolean;
}

class QuickFilters extends PureComponent<Props> {
  static defaultProps = {
    onChangeFilter: () => null,
  };

  render() {
    const {
      tableName,
      onResetFilters,
      hasSelectedQuickFilters,
      hasSelectedFilters,
      isAdmin,
    } = this.props;

    return (
      <div className='quick-filters quick-filters_team'>
        <Animation>
          <div className='quick-filters__inner'>
            {!hasSelectedFilters && (
              <>
                <div className='quick-filters__item'>
                  {this.renderProviderName()}
                </div>
                <div className='quick-filters__item'>{this.renderWlId()}</div>
              </>
            )}
            <div className='quick-filters__item quick-filters__item_settings'>
              <div className='quick-filters__filter-settings ui-buttons-group_single-choice'>
                <div className='ui-buttons-group__inner'>
                  <Button
                    status='danger-light'
                    id='reload'
                    icon='im-Reset-filter'
                    onClick={onResetFilters}
                    customClass='ui-button_no-border'
                    disabled={!hasSelectedQuickFilters && !hasSelectedFilters}
                  />
                  {!isAdmin && <FiltersButton name={tableName} />}
                </div>
              </div>
            </div>
          </div>
        </Animation>
      </div>
    );
  }

  renderProviderName = () => {
    const { filters, onChangeFilter, getTranslate } = this.props;

    return (
      <Input
        placeholder={getTranslate('providers.quickFilters.providerTitle.label')}
        id='providers-enter-provider-title'
        value={filters[filtersKeys.title]}
        onChange={(e) => {
          MetricService.sendInputField(
            'providers.fastFilter.providerTitle.enter'
          );
          onChangeFilter(filtersKeys.title, e.target.value);
        }}
        customClass='ui-input_quick-filter'
      />
    );
  };

  renderWlId() {
    const { filters, onChangeFilter, getTranslate } = this.props;

    return (
      <Input
        placeholder={getTranslate('providers.quickFilters.wlId.label')}
        id='providers-enter-wl-id'
        value={filters[filtersKeys.wl_id]}
        onChange={(e) => {
          MetricService.sendInputField('providers.fastFilter.wlId.enter');
          onChangeFilter(filtersKeys.wl_id, e.target.value);
        }}
        customClass='ui-input_quick-filter'
      />
    );
  }
}

export default addTranslation(QuickFilters);
