import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';
import { GetProvidersPayload } from 'types/Api';

export const getProviders = (payload: GetProvidersPayload) => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.Provider_List,
          payload,
        },
      ],
      isCancelable: true,
    })
    .then((data) => ({ ...data, items: data.rows || [], totalRows: Infinity }));
};

export const getProvider = (providerId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Provider_Get,
        payload: { providerId },
      },
    ],
  });
};

export const createProvider = (payload) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Provider_Create,
        payload,
      },
    ],
    showValidateNotification: false,
  });
};

export const updateProvider = (payload) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Provider_Update,
        payload,
      },
    ],
    showValidateNotification: false,
  });
};

export const deleteProvider = (providerId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Provider_Delete,
        payload: { providerId },
      },
    ],
  });
};

export const disableProvider = (providerId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Provider_Disable,
        payload: { providerId },
      },
    ],
  });
};

export const enableProvider = (providerId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Provider_Enable,
        payload: { providerId },
      },
    ],
  });
};
