import LocalStorage from 'helpers/LocalStorage';
import { Width } from 'types/ItemConfiguration';

export const parseColumnsWidthsFromHeader = (headerRef, clientWidth?: number) => {
  const children = headerRef && headerRef.children;
  const columnFullWidth = clientWidth ? Math.floor(clientWidth / children.length) : null;
  if (children && children.length > 1) {
    return Array.from(children).reduce(
      (acc: any, value: any) => ({
        ...acc,
        [value.id]: columnFullWidth || value.offsetWidth,
      }),
      {}
    ) as Width;
  }
  return undefined;
};

export const getTableWidthFromStorage = (tableName) => {
  const widths = LocalStorage.get('tableWidths');
  return widths && widths[tableName];
};

export const memorizeTableWidth = (tableName, width) => {
  const tableWidths = LocalStorage.get('tableWidths') || {};
  tableWidths[tableName] = width;
  LocalStorage.set('tableWidths', tableWidths);
};

export const isEmpty = (obj) => {
  return !obj || !Object.keys(obj).length;
};
