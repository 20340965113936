import React, { PropsWithChildren, PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import PageTemplate from 'components/pageTemplate/PageTemplate';
import FormFieldsContainer from 'components/formFields';
import FormFieldsComponents from 'components/formFields/components/constructorComponents';
import { WlProviderFields } from './WlProviderFieldsInterface';
import MetricService from 'helpers/metricService/MetricService';
import './wlProviderBuilder.scss';

interface OwnProps extends IntlProps {
  fields: WlProviderFields;
  isEditing: boolean;
  isLoading: boolean;
  canEdit: boolean;
  canSendForm: boolean;
  isSubmitted: boolean;
  onSend: () => void;
  backendError: boolean;
  renderForm: () => JSX.Element | null;
  backToForm: () => void;
}

type Props = PropsWithChildren<OwnProps>;

class WlProviderBuilder extends PureComponent<Props> {
  render() {
    const { isSubmitted, isLoading } = this.props;

    return (
      <PageTemplate.Main topPadding>
        <PageTemplate.Container>
          <FormFieldsContainer
            id='wl-provider-builder'
            title={this.getFormTitle()}
            onReset={this.props.backToForm}
            isLoading={isLoading}
            formFields={this.renderForm()}
            isSubmitted={isSubmitted}
            submittedTitle={this.getSubmittedTitle()}
            submittedText={this.getSubmittedText()}
            backUrl={this.getBackButtonUrl()}
            backText='providers.add_wlprovider.back.button'
            repeatCreateButtonText={this.getGoToFormButtonText()}
          />
        </PageTemplate.Container>
      </PageTemplate.Main>
    );
  }

  renderForm = () => {
    const {
      getTranslate,
    } = this.props;
    return (
      <FormFieldsComponents.Container onSubmit={this.props.onSend}>
        <FormFieldsComponents.Content
          text={getTranslate('providers.add_provider.infoText')}>
          <FormFieldsComponents.GeneralColumn
            title={''}>
            {this.props.renderForm()}
          </FormFieldsComponents.GeneralColumn>
        </FormFieldsComponents.Content>
        <FormFieldsComponents.Footer
          backUrl={this.getBackButtonUrl()}
          onBack={this.onBack}
          backText={getTranslate('providers.add_wlprovider.back.button')}
          isLoading={this.props.isLoading}
          createButtonText={this.getApplyButtonText()}
          repeatCreateButtonText={this.getGoToFormButtonText()}
          canCreate={this.props.canEdit && this.props.canSendForm}
        />
      </FormFieldsComponents.Container>
    );
  };

  getFormTitle() {
    const { getTranslate, isEditing } = this.props;
    return isEditing ?
        getTranslate('providers.edit_provider.header') :
        getTranslate('providers.add_provider.header');
  }

  getSubmittedTitle = () => {
    const { isEditing } = this.props;
    return isEditing
      ? 'providers.edit_provider.success.header'
      : 'providers.add_provider.success.header';
  };

  getSubmittedText = () => {
    return <></>;
  };

  getApplyButtonText = () => {
    const { isEditing, getTranslate } = this.props;
    return isEditing
      ? getTranslate('providers.edit_provider.save_changes.button')
      : getTranslate('providers.add_provider.create.button');
  };

  getBackButtonUrl = () => {
    return '/providers';
  };

  getGoToFormButtonText = () => {
    const { isEditing } = this.props;
    return isEditing
        ? 'providers.editProvider.backToProvider'
        : 'providers.editProvider.backToCreate';
  };

  onBack = () => {
    MetricService.send({
      action: 'click',
      actionKey: 'providers.newProvider.backToRegistry',
    });
  };
}

export default addTranslation(WlProviderBuilder);
