export enum FieldsType {
  text = 'text',
  singleSelect = 'singleSelect',
  switch = 'switch',
}

export const fieldsConfig = (isEditing) => {
  let result = [
    {
      type: FieldsType.singleSelect,
      labelId: 'providers.wlProviderList.providerId.header',
      name: 'provider_id',
      placeholderId: 'providers.wlProviderList.providerId.header',
    },
    {
      type: FieldsType.text,
      labelId: 'providers.wlProviderList.providerTitle.header',
      name: 'title',
      placeholderId: 'providers.wlProviderList.providerTitle.header',
    },
    {
      type: FieldsType.singleSelect,
      labelId: 'providers.wlProviderList.wl.header',
      name: 'wl_id',
      placeholderId: 'providers.wlProviderList.wl.header',
    },
    {
      type: FieldsType.singleSelect,
      labelId: 'providers.wlProviderList.status.header',
      name: 'status',
      placeholderId: 'providers.wlProviderList.status.header',
    },
  ];
  
  if (isEditing) {
    const editingFields = [
      {
        type: FieldsType.text,
        labelId: 'providers.wlProviderList.createdAt.header',
        name: 'created_at',
        placeholderId: 'providers.wlProviderList.createdAt.header',
        disabled: true,
      },
      {
        type: FieldsType.text,
        labelId: 'providers.wlProviderList.updatedAt.header',
        name: 'updated_at',
        placeholderId: 'providers.wlProviderList.updatedAt.header',
        disabled: true,
      },
      {
        type: FieldsType.text,
        labelId: 'providers.wlProviderList.createdBy.header',
        name: 'created_by',
        placeholderId: 'providers.wlProviderList.createdBy.header',
        disabled: true,
      },
      {
        type: FieldsType.text,
        labelId: 'providers.wlProviderList.updatedBy.header',
        name: 'updated_by',
        placeholderId: 'providers.wlProviderList.updatedBy.header',
        disabled: true,
      },
    ];
    result = [...result, ...editingFields];
  }
  
  return result;
};
