import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { loadAdminDictionaries } from 'api/dictionaries';
import { resetConcreteQuickFilters } from 'actions/quickFilters';
import { resetFilters } from 'actions/filters';
import { updateFiltersValues } from 'actions/filtersValues';
import QuickFiltersContainer from 'components/quickFiltersContainer';
import QuickFilters from '../quickFilters';
import QuickAdminFilters from 'pages/admin/components/quickFilters';
import SelectedFiltersContainer from 'components/selectedFilters';
import getFiltersValues from 'selectors/getFiltersValues';
import checkFilters from 'helpers/checkFilters';
import getSelectionListItems from 'creators/getSelectionListItems';
import getQuickFiltersValues from 'selectors/getQuickFiltersValues';
import { StoreProps } from 'store';
import { QuickFiltersTableName } from 'types/QuickFilters';
import CreateProviderButton from '../createProviderButton/index';
import breakpoints from 'constants/breakpoints';
import tableNames from "../../../../constants/tableNames";

interface OwnProps {
  tableName: string;
  quickFilter: QuickFiltersTableName;
  onChangeCurrentTable?: (tableName) => void;
}

interface ConnectedProps {
  filtersValues: {
    paymentSystemsList: any;
    wlList: any;
    statuses: any;
  };
  hasSelectedFilters: boolean;
  hasSelectedQuickFilters: boolean;
}

type Props = OwnProps & ConnectedProps & StoreProps;

interface State {
  showAllFilters: any;
  availableWlItems: any[];
  paymentSystemsList: any[];
  statuses: any[];
  scrollEnd: 'left' | 'right' | undefined;
}

class TopPanelContainer extends Component<Props, State> {
  private topPanel;
  observer;
  constructor(props) {
    super(props);
    this.state = {
      showAllFilters: false,
      availableWlItems: [],
      paymentSystemsList: [],
      statuses: [],
      scrollEnd: 'left',
    };
    this.observer = (() => {
      const userAgent = navigator.userAgent.toLowerCase();
      if (
        userAgent.includes('safari') &&
        !userAgent.includes('chrome') &&
        window.innerWidth <= breakpoints.quickFilters
      ) {
        return new MutationObserver(this.watchOpenedPanels);
      }
    })();
  }

  async componentDidMount() {
    await checkFilters(tableNames.providers);
    this.initState();
    if (this.observer) {
      this.observer.observe(this.topPanel, { childList: true, subtree: true });
    }
  }

  render() {
    const { showAllFilters, availableWlItems, paymentSystemsList, statuses, scrollEnd } = this.state;
    const {
      tableName,
      quickFilter,
      filtersValues,
      onChangeCurrentTable,
      hasSelectedFilters,
      hasSelectedQuickFilters,
    } = this.props;

    const QuickFiltersComponent: any = QuickFilters;

    return (
      <div
        className={classNames('top-panel top-panel_team', {
          'top-panel_selected': hasSelectedFilters,
          'top-panel_hide-left': scrollEnd === 'left',
          'top-panel_hide-right':
            scrollEnd === 'right' || (hasSelectedFilters && this.isSameWidth()),
        })}
        ref={(el) => {
          this.topPanel = el;
        }}>
        <div className='top-panel__inner' onScroll={this.handleScroll}>
          {hasSelectedFilters && <SelectedFiltersContainer name={tableName} />}
          {!hasSelectedFilters && (
            <div className='top-panel__button-wrap'>
              <CreateProviderButton
                customClass='top-panel__button'
              />
            </div>
          )}
          <div className='top-panel__filters' onScroll={this.handleScroll}>
            <QuickFiltersContainer tableName={quickFilter}>
              {(props) => (
                <QuickFiltersComponent
                  filters={props.filters}
                  onChangeFilter={props.onChangeFilter}
                  updateFiltersValues={props.updateFiltersValues}
                  name={props.name}
                  showAllFilters={showAllFilters}
                  filtersValues={filtersValues}
                  tableName={tableName}
                  onChangeCurrentTable={onChangeCurrentTable}
                  availableWlItems={availableWlItems}
                  availablePaymentSystems={paymentSystemsList}
                  statuses={statuses}
                  onResetFilters={this.resetFilters}
                  hasSelectedQuickFilters={hasSelectedQuickFilters}
                  hasSelectedFilters={hasSelectedFilters}
                />
              )}
            </QuickFiltersContainer>
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.observer?.disconnect();
  }

  resetFilters = async () => {
    const { dispatch, tableName, quickFilter: name } = this.props;
    dispatch(
      resetConcreteQuickFilters({
        name,
      })
    );

    dispatch(resetFilters(tableName));
  };

  initState = () => {
    const {
      filtersValues: {
        wlList,
        paymentSystemsList,
        statuses
      }
    } = this.props;
    if (!wlList || !paymentSystemsList || !statuses) return [];
    const availableWlItems = getSelectionListItems({
      originalList: wlList,
      selectedList: [],
      getText: (item) => item.text,
    });
    const availablePaymentSystems = getSelectionListItems({
      originalList: paymentSystemsList,
      selectedList: [],
      getText: (item) => item.text,
    });
    const availableStatuses = getSelectionListItems({
      originalList: statuses,
      selectedList: [],
      getText: (item) => item.text,
    });
    this.setState({
      availableWlItems: availableWlItems,
      paymentSystemsList: availablePaymentSystems,
      statuses: availableStatuses
    });
  };

  isSameWidth = () => {
    if (!this.topPanel) return false;
    return (
      this.topPanel.clientWidth ===
      this.topPanel.querySelector('.top-panel__inner').scrollWidth
    );
  };

  handleScroll = (e) => {
    if (
      !e.target.classList.contains('top-panel__filters') &&
      !e.target.classList.contains('top-panel__inner')
    )
      return;
    if (e.target.scrollLeft === 0) {
      this.setState({
        scrollEnd: 'left',
      });
    } else if (
      e.target.scrollWidth - e.target.scrollLeft ===
      e.target.clientWidth
    ) {
      this.setState({
        scrollEnd: 'right',
      });
    } else {
      this.setState({
        scrollEnd: undefined,
      });
    }
  };

  setScrollToStart = () => {
    if (!this.topPanel) return false;
    const { hasSelectedFilters } = this.props;
    if (hasSelectedFilters) {
      this.topPanel.querySelector('.top-panel__inner').scrollTo(0, 0);
    } else {
      this.topPanel.querySelector('.top-panel__filters').scrollTo(0, 0);
    }
  };

  watchOpenedPanels = () => {
    const panel = this.topPanel.querySelector('.ui-panel');
    if (panel) {
      this.topPanel.classList.add('top-panel_visible');
    } else {
      this.topPanel.classList.remove('top-panel_visible');
    }
  };
}

const mapStateToProps = (state, ownProps: OwnProps): ConnectedProps => {
  const { filtersValues } = state;
  return {
    filtersValues: {
      paymentSystemsList: filtersValues.allPaymentSystems?.list,
      wlList: filtersValues.allWlIds?.list,
      statuses: filtersValues.wlProviderStatus?.list,
    },
    hasSelectedQuickFilters:
      Object.keys(getQuickFiltersValues(state, ownProps.tableName, true))
        .length > 0,
    hasSelectedFilters:
      Object.keys(getFiltersValues(state, ownProps.tableName, true)).length > 0,
  };
};
export default connect(mapStateToProps)(TopPanelContainer);
