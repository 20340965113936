import React from 'react';
import { IntlProps } from 'decorators/addTranslation';
import Input from 'components/ui/input';
import CustomSelect from 'components/ui/customSelect';
import { FieldsType } from './FieldsConfig';
import { WlProviderFields } from './WlProviderFieldsInterface';
import { AnyObject } from 'types/Common';

interface Props extends IntlProps {
  updateFiltersValues: (key: string, value: AnyObject) => void;
  fieldsConfig: AnyObject[];
  disableForm?: boolean;
  fields: WlProviderFields;
  validationErrors: AnyObject;
  onChangeField: (key: string, value: any, param?: string) => void;
}

export default class WlProviderFormSection extends React.PureComponent<Props> {
  render() {
    const {
      fields,
      fieldsConfig,
      getTranslate,
      validationErrors,
      onChangeField,
    } = this.props;

    return (
      <>
        {fieldsConfig.map((configItem) => {
          const {
            type,
            labelId,
            name: id,
            placeholderId,
            disabled = false,
            valueProp,
            filterProp,
            connectedTo,
            ...restProps
          } = configItem;

          switch (type) {
            case FieldsType.text: {
              return (
                <div className='ui-form-fields__item' key={id}>
                  <Input
                    {...restProps}
                    label={getTranslate(labelId)}
                    customClass='ui-form-fields__field'
                    placeholder={getTranslate(placeholderId)}
                    id={id}
                    value={fields[id]}
                    disabled={disabled}
                    error={getTranslate(validationErrors[id])}
                    onChange={({ target }) => onChangeField(id, target.value)}
                    modern
                  />
                </div>
              );
            }
            case FieldsType.singleSelect: {
              return (
                <div className='ui-form-fields__item' key={id}>
                  <CustomSelect
                    id={id}
                    label={getTranslate(labelId)}
                    value={fields[id].value}
                    options={fields[id].list}
                    onChange={(item) =>
                      onChangeField(id, {
                        value: item,
                        list: fields[id].list,
                        selected: item.value,
                      })
                    }
                    error={getTranslate(validationErrors[id])}
                    customClass='ui-form-fields__field ui-select_no-min-width'
                    containerSelector='.ui-form-fields__content'
                    modern
                    isSearchable={true}
                  />
                </div>
              );
            }
            default:
              return null;
          }
        })}
      </>
    );
  }
}
