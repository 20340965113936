import BaseMessageHandler from '../BaseMessageHandler';
import { updateDataItem } from 'actions/getData';
import tableNames from 'constants/tableNames';

export default class Provider_Update extends BaseMessageHandler {
  success = () => {
    const { dispatch, message } = this;
    const { payload } = message;

    dispatch(
      updateDataItem(tableNames.providers, 'id', {
        ...payload,
      })
    );
  };
}
