import BaseMessageHandler from '../BaseMessageHandler';
import { deleteDataItem } from 'actions/getData';
import tableNames from 'constants/tableNames';

export default class Provider_Delete extends BaseMessageHandler {
  success = () => {
    const { dispatch, message } = this;
    const { payload } = message;

    dispatch(deleteDataItem(tableNames.providers, 'id', payload.id));
  };
}
