import React, { Component } from 'react';
import { connect } from 'react-redux';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';
import {
  deleteProvider,
  disableProvider,
  enableProvider,
  getProviders,
} from 'api/providers';
import getConfigurationByName from 'selectors/getConfigurationByName';
import Providers from './ProvidersPage';
import tableNames from 'constants/tableNames';
import path from 'helpers/path';
import { AnyObject } from 'types/Common';
import { RootState, StoreProps } from 'store';
import { addListeners } from '../../decorators/addListeners';
import Messages from '../../constants/rpcTypes';
import { PROVIDER_STATUS_ACTIVE } from './constants';

interface ConnectedProps {
  configuration: AnyObject;
  quickFilters: AnyObject;
  filtersValues: AnyObject;
}

type Props = ConnectedProps & StoreProps & IntlProps & WithRouterProps;

interface State {
  isLoading: boolean;
}

@addListeners([
  Messages.Provider_Delete,
  Messages.Provider_Enable,
  Messages.Provider_Disable,
])
class ProvidersContainer extends Component<Props, State> {
  state = { isLoading: false };

  render() {
    const { isLoading } = this.state;
    const { configuration } = this.props;
    return (
      <Providers
        canRenderTable
        tableName={tableNames.providers}
        isLoading={isLoading}
        apiRequest={getProviders}
        onToggleActivate={(data) => this.toggleActivate(data)}
        configuration={configuration}
        onEditProvider={(id) => this.editProvider(id)}
        onDeleteProvider={(id) => this.deleteProvider(id)}
      />
    );
  }

  async toggleActivate(data) {
    try {
      data.status === PROVIDER_STATUS_ACTIVE
        ? await disableProvider(data.id)
        : await enableProvider(data.id);
    } catch (e) {
      console.error(e);
    }
  }

  async deleteProvider(id: number) {
    this.setState({ isLoading: true });
    try {
      await deleteProvider(id);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async editProvider(id: number) {
    const { history } = this.props;
    history.push(path(`/providers/edit/${id}`));
  }
}

const mapStateToProps = (state: RootState): ConnectedProps => ({
  configuration: getConfigurationByName(state, 'providers'),
  quickFilters: state.quickFilters.providers,
  filtersValues: state.filtersValues,
});

export default connect(mapStateToProps)(addTranslation(ProvidersContainer));
